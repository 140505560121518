.ssagroindiacompany{
    background-color: aliceblue;
}

.buttontop{
    background-color: rgb(4, 95, 170); /* Green */
    border: none;
    color: white;
    padding: 4px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}

.button2 {
    background-color: rgb(255, 255, 255); 
    color: black; 
    border: 2px solid rgb(4, 95, 170);
  }
  
  .button2:hover {
    background-color: rgb(4, 95, 170);
    color: white;
  }
  

  

.tagcolor Link{
  color: red;

}

.psluxury {
  background-color: rgb(119, 59, 2);
  padding: 1px;
  font-family: 'Croissant One', cursive;
}


.headlogo{
  margin-left: 25px;
  display: flex;
  width:210px;
  height: 50px;
}
.logandsign{
  margin-left: 20px;
  padding: 25px;
   
  /* padding-right: 10px;
  letter-spacing: 1.5px; */
}
/* .first{
  margin:02px 03px;
  padding: 0 20px;
  border: 1px solid #46c4d4;
  border-radius: 25px;
  cursor: pointer;
  overflow: hidden;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);   
} */
.first :hover {
  transform: scale(1.05);
}
/* .for_providers{
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
} */


.logosider{
margin-left: 10px;
}

.text {
  font-family: 'Ubuntu', sans-serif;
}

.buttonlogin{
  background-color: #d7d4e6; /* Green */
  border: none;
  color: white;
  border-radius: 12px;
  padding: 7px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}


.mrmondalanalytics{
  background-color: aliceblue;
}

.buttonbook{
  background-color: rgb(235, 242, 247); /* Green */
  border: none;
  color: white;
  border-radius: 12px;
  padding: 4px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
}









/* Dropdown Button */
.dropbtn {
  background-color: rgb(255, 255, 255);
  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;
   border-radius: 14px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff0f0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(255, 249, 249, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: rgb(51, 47, 47);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: rgb(255, 244, 244)}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: rgb(254, 254, 255);}






.dropbtn{
  color: rgb(57, 57, 53);
}

