.hetelsangam{
     
        height:  360px;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-image: url(https://i.postimg.cc/kXCJB8wD/Whats-App-Image-2023-11-27-at-12-24-31-AM.jpg);
        background-attachment: fixed;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    
      }

      .buttoncall {
        background-color: #04AA6D; /* Green */
        border: none;
        color: white;
        padding: 8px 17px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        border-radius: 12px;
      }

      .button1 {
        background-color: rgb(255, 255, 255); 
        color: black; 
        border: 2px solid rgb(4, 95, 170);
      }
      
      .button1:hover {
        background-color: rgb(4, 95, 170);
        color: white;
       
      }



      .containerdeta {
        position: relative;
        width: 100%;
      }
      
      .image {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s ease;
        backface-visibility: hidden;
      }
      
      .middle {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
      
      .containerdeta:hover .image {
        opacity: 0.3;
      }
      
      .containerdeta:hover .middle {
        opacity: 1;
      }
      
      .text {
        background-color: rgb(4, 95, 170);
        color: white;
        font-size: 16px;
        padding: 7px 18px;
        border-radius: 12px;
      }
      .containerdeta img{
        border-radius: 16px;
      }

     

      .images1 source{
        border-radius: 19px;;
      }

      .roomcheck{
        background-color: rgb(4, 95, 170); /* Green */
  border: none;
  color: white;
  padding: 15px 70px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
      }

      .menu{
        background-color: rgb(4, 95, 170); /* Green */
  border: none;
  color: white;
  padding: 14px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
      }