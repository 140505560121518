.aboutusback{
    height:  350px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/CMzcTNQk/IMG-20231120-162741.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
   
    align-items: center;
}