.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.buttonnav{
  background-color: #04AA6D; /* Green */
  border: none;
  color: white;
  padding: 6px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
}

.button2 {
  background-color: rgb(255, 255, 255); 
  color: black; 
  border: 2px solid rgb(4, 95, 170);
}

.button2:hover {
  background-color: rgb(4, 95, 170);
  color: white;
}

a {
  text-decoration: none !important;
 
  
}

.texts{
  text-align: justify;
  text-justify: inter-word;
}


 


 .container{
  font-family: 'Roboto Flex', sans-serif;
 }

 .buttoncontact{
  background-color: rgb(4, 95, 170); /* Green */
  border: none;
  color: white;
  padding: 6px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
 }

 .textcolor{
  color:rgb(4, 95, 170);
  font-family: 'Noto Sans', sans-serif;
 }

 .buttonbbom{
  background-color: rgb(4, 95, 170); /* Green */
  border: none;
  color: white;
  padding: 11px 95px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
 }

 .buttonevent{
  background-color: rgb(4, 95, 170); /* Green */
  border: none;
  color: white;
  padding: 5px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
 }

 .eventbook{
  background-color: rgb(4, 95, 170); /* Green */
  border: none;
  color: white;
  padding: 7px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
 }




.backg{
  background-color: rgb(10, 76, 114);
  padding: 20px;
}
