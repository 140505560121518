.buttonmenu{
    background-color: rgb(4, 95, 170); /* Green */
    border: none;
    color: white;
    padding: 5px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 2px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}